const getToday = (): Date => {
  const now = new Date(Date.now());
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return new Date(Date.UTC(year, month, day));
};

const JANUARY_2008 = new Date(Date.UTC(2008, 0, 1));
const TODAY = getToday();

export const Dates = { JANUARY_2008, TODAY };
