import { Months } from '../Months';
import { MonthYear } from '../MonthYear';

const { JANUARY, DECEMBER } = Months;

function getPrevMonth([month, year]: MonthYear): MonthYear {
  if (month === JANUARY + 1) {
    return [DECEMBER + 1, year - 1];
  }

  return [month - 1, year];
}

export default getPrevMonth;
