import * as React from 'react';

function CancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="7 0 10 16" height="16" {...props}>
      <path
        d="M7.96 11.03L10.99 8 7.96 4.97l1.01-1.01L12 6.99l3.03-3.03 1.01 1.01L13.01 8l3.03 3.03-1.01 1.01L12 9.01l-3.03 3.03z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CancelIcon;
