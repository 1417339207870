import React, {
  Component,
  ChangeEvent,
  KeyboardEvent,
  SyntheticEvent,
  FocusEventHandler,
  createRef,
} from 'react';
import styled from 'styled-components';

import isWithinRange from 'date-fns/is_within_range';
import formatDate from 'date-fns/format';

import './DatePicker.css';

import getPrevMonth from './utils/getPrevMonth';
import getNextMonth from './utils/getNextMonth';
import clampMonthAndYear from './utils/clampMonthAndYear';

import { getYearsBetweenDates } from './getYearsBetweenDates';

import { CalendarMonth } from './CalendarMonth';
import { Dates } from './Dates';
import { Button } from '../Button/Button';
import { Input } from '../Form/Input';
import { tombac } from '../../shared/tombac';

import DateIcon from './icons/DateIcon';
import CancelIcon from './icons/CancelIcon';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';
import { PropsWithPropStyling, propStyling } from '../../shared/propStyling';
import { extractStylingProps } from '../../shared/propStyling/propStylingUtils';

const INITIAL_STATE = {
  currentMonth: new Date().getMonth() + 1,
  currentYear: new Date().getFullYear(),
};

function defaultParseValue(inputValue: any, dateFormat: string): string {
  return inputValue && inputValue instanceof Date
    ? formatDate(inputValue, dateFormat)
    : '';
}

export interface DatePickerCoreProps {
  className?: string;
  calendarClassName?: string;
  clearable?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  isOpen?: boolean;
  id?: string;
  value?: Date;
  onChange?: (date?: Date | null) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void;
  locale?: string;
  weekStartsOn?: number;
  placeholder?: string;
  dateFormat?: string;
  parseValue?: (value: any, format: string) => string;
}

export type DatePickerProps = PropsWithPropStyling<DatePickerCoreProps>;

interface State {
  value: string;
  currentMonth: number;
  currentYear: number;
  isFocused: boolean;
  isOpen: boolean;
}

const CALENDAR_MONTH_CLASSNAMES = {
  availableClass: 'CalendarMonth-day--available DatePicker__day--available',
  betweenClass: 'CalendarMonth-day--between DatePicker__day--between',
  betweenEdgeClass: 'CalendarMonth-day--edge DatePicker__day--edge',
  startClass: 'CalendarMonth-day--start DatePicker__day--start',
  endClass: 'CalendarMonth-day--end DatePicker__day--end',
  todayClass: 'CalendarMonth-day--today DatePicker__day--today',
};

const Container = styled.div<PropsWithPropStyling>`
  display: inline-block;
  position: relative;
  width: ${tombac.space(20)};
  ${propStyling}

  & > .TombacInput {
    width: 100%;
  }
`;

export class DatePicker extends Component<DatePickerProps, State> {
  static defaultProps = {
    className: '',
    calendarClassName: '',
    minDate: Dates.JANUARY_2008,
    maxDate: Dates.TODAY,
    dateFormat: 'YYYY-MM-DD',
    locale: 'en-GB',
    weekStartsOn: 1,
    parseValue: defaultParseValue,
  };

  private containerRef = createRef<HTMLDivElement>();

  constructor(props: Readonly<DatePickerProps>) {
    super(props);

    this.state = {
      isFocused: false,
      isOpen: props.isOpen ?? false,
      value: props.parseValue!(props.value, props.dateFormat!),
      ...INITIAL_STATE,
    };

    this.showNextMonth = this.showNextMonth.bind(this);
    this.showPrevMonth = this.showPrevMonth.bind(this);

    this.onYearSelected = this.onYearSelected.bind(this);

    this.returnToToday = this.returnToToday.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillReceiveProps(props: Readonly<DatePickerProps>) {
    const { value, minDate, maxDate, dateFormat, parseValue, isOpen } = props;
    const month =
      value instanceof Date ? value.getUTCMonth() + 1 : this.state.currentMonth;
    const year =
      value instanceof Date ? value.getUTCFullYear() : this.state.currentYear;

    const { month: currentMonth, year: currentYear } = clampMonthAndYear(
      month,
      year,
      minDate!,
      maxDate!,
    );

    this.setState((prevState) => ({
      value: parseValue!(value, dateFormat!),
      currentMonth,
      currentYear,
      isOpen: isOpen ?? prevState.isOpen,
    }));
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  componentDidUpdate(prevProps: DatePickerProps) {
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen !== undefined
    ) {
      this.setState(() => ({ isOpen: this.props.isOpen ?? false }));
    }
  }

  returnToToday() {
    this.setState({
      ...INITIAL_STATE,
    });
  }

  showNextMonth() {
    this.setState((state) => {
      const nextMonth = getNextMonth([state.currentMonth, state.currentYear]);

      const [currentMonth, currentYear] = nextMonth;

      return {
        ...state,
        currentMonth,
        currentYear,
      };
    });
  }

  showPrevMonth() {
    this.setState((state) => {
      const prevMonth = getPrevMonth([state.currentMonth, state.currentYear]);

      const [currentMonth, currentYear] = prevMonth;

      return {
        ...state,
        currentMonth,
        currentYear,
      };
    });
  }

  onYearSelected(e: ChangeEvent<HTMLSelectElement>) {
    const { minDate, maxDate } = this.props;

    const selectedMonth = this.state.currentMonth;
    const selectedYear = parseInt(e.target.value, 10);
    const { month: clampedMonth } = clampMonthAndYear(
      selectedMonth,
      selectedYear,
      minDate!,
      maxDate!,
    );

    this.setState({
      currentYear: selectedYear,
      currentMonth: clampedMonth,
    });
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    this.setState({
      value,
    });
  }

  handleBlur(e: SyntheticEvent<HTMLInputElement>) {
    const { onChange, minDate, maxDate, onBlur } = this.props;

    if (onBlur) {
      onBlur(e);
      return;
    }

    const { value } = e.currentTarget;
    const [day, month, year] = value.split('/');

    const validDate = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day)),
    );
    const isBetween =
      minDate && maxDate ? isWithinRange(validDate, minDate, maxDate) : false;

    if (!validDate) {
      if (onChange) onChange(null);
      return;
    }

    if (onChange && validDate && isBetween) {
      onChange(validDate);
    }
  }

  handleClick() {
    this.setState(() => ({
      isOpen: this.props.isOpen ?? true,
      isFocused: true,
    }));
  }

  handleDocumentClick(e: MouseEvent) {
    if (!this.containerRef.current?.contains(e.target as Node))
      this.setState(() => ({
        isOpen: this.props.isOpen ?? false,
        isFocused: false,
      }));
  }

  handleDayClick(e: Date | null | undefined) {
    const { isOpen, onChange } = this.props;
    this.setState(() => ({
      isOpen: isOpen ?? false,
    }));
    if (onChange) onChange(e);
  }

  handleKeyPress(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      this.handleBlur(e);
    }
  }

  render() {
    const {
      className,
      calendarClassName,
      clearable,
      disabled,
      startDate,
      endDate,
      minDate,
      maxDate,
      locale,
      weekStartsOn,
      placeholder,
      id,
      onChange,
      onFocus,
    } = this.props;
    const { currentMonth, currentYear, isFocused, isOpen, value } = this.state;

    const currentPair = [currentYear, currentMonth];

    const months = [currentPair];

    const [stylingProps] = extractStylingProps(this.props);

    const renderedMonths = months.map(([year, month]) => (
      <CalendarMonth
        key={`${year}-${month}`}
        year={year}
        month={month}
        onDayClicked={this.handleDayClick}
        startDate={startDate || endDate ? startDate : this.props.value}
        endDate={startDate || endDate ? endDate : this.props.value}
        minDate={minDate}
        maxDate={maxDate}
        locale={locale}
        weekStartsOn={weekStartsOn}
        hideMonthName
        {...CALENDAR_MONTH_CLASSNAMES}
      />
    ));

    const resetButton = (
      <CancelIcon
        className="DatePicker-reset"
        onClick={() => onChange && onChange(undefined)}
      />
    );

    const input = (
      <Input
        className="DatePicker-input"
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        value={value}
        prepend={<DateIcon />}
        append={value !== '' && clearable && resetButton}
        highlighted={isFocused}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onClick={this.handleClick}
        onFocus={onFocus}
        onKeyPress={this.handleKeyPress}
        autoComplete="off"
      />
    );

    const openCalendarClass = isOpen ? 'DatePicker-calendar--open' : '';

    const someDayOfTheMonth = new Date(Date.UTC(2000, currentMonth - 1, 2));
    const monthName = new Intl.DateTimeFormat(locale, { month: 'long' }).format(
      someDayOfTheMonth,
    );

    const availableYears = getYearsBetweenDates(minDate!, maxDate!);
    const yearSelect = (
      <select
        className="DatePicker-year-select"
        value={currentYear}
        onChange={this.onYearSelected}
      >
        {availableYears.map((year) => (
          <option key={year}>{year}</option>
        ))}
      </select>
    );

    const isPrevMonthAvailable =
      currentYear > minDate!.getFullYear() ||
      (currentYear === minDate!.getFullYear() &&
        currentMonth > minDate!.getMonth() + 1);

    const prevMonth = (
      <Button
        className={
          'DatePicker-year-prev' +
          (isPrevMonthAvailable ? ' DatePicker-year-prev--active' : '')
        }
        onClick={isPrevMonthAvailable ? this.showPrevMonth : undefined}
        prepend={<ChevronLeftIcon />}
        variant="flat"
        size="s"
        type="button"
      />
    );

    const isNextMonthAvailable =
      currentYear < maxDate!.getFullYear() ||
      (currentYear === maxDate!.getFullYear() &&
        currentMonth < maxDate!.getMonth() + 1);

    const nextMonth = (
      <Button
        className={
          'DatePicker-year-next' +
          (isNextMonthAvailable ? ' DatePicker-year-next--active' : '')
        }
        onClick={isNextMonthAvailable ? this.showNextMonth : undefined}
        prepend={<ChevronRightIcon />}
        variant="flat"
        size="s"
        type="button"
      />
    );

    const calendar = (
      <div
        className={`DatePicker-calendar ${openCalendarClass} ${calendarClassName}`}
      >
        <div className="DatePicker-year">
          {prevMonth}
          <span className="DatePicker-year-label">
            {monthName} {yearSelect}{' '}
            {/* <FaIcon
              className="DatePicker-year-select-caret"
              type="caret-down"
            /> */}
          </span>
          {nextMonth}
        </div>
        <div className="DatePicker-months">{renderedMonths}</div>
      </div>
    );

    return (
      <Container
        className={`TombacDatePicker ${className}`}
        {...stylingProps}
        ref={this.containerRef}
      >
        {input}
        {calendar}
      </Container>
    );
  }
}
