export default function clampMonthAndYear(month: number, year: number, minDate: Date, maxDate: Date) {
  const minMonth = minDate.getUTCMonth() + 1;
  const minYear = minDate.getUTCFullYear();
  const maxMonth = maxDate.getUTCMonth() + 1;
  const maxYear = maxDate.getUTCFullYear();

  const clampedMonthIfTooHigh =
    year > maxYear || (year === maxYear && month >= maxMonth)
      ? maxMonth
      : month;

  const clampedMonth =
    year < minYear || (year === minYear && month <= minMonth)
      ? minMonth
      : clampedMonthIfTooHigh;

  const clampedYear = Math.min(Math.max(minYear, year), maxYear);

  return {
    month: clampedMonth,
    year: clampedYear
  };
}
