import { Months } from '../Months';
import { MonthYear } from '../MonthYear';

const { JANUARY, DECEMBER } = Months;

function getNextMonth([month, year]: MonthYear): MonthYear {
  if (month === DECEMBER + 1) {
    return [JANUARY + 1, year + 1];
  }

  return [month + 1, year];
}

export default getNextMonth;
