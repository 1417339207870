import React, {
  ElementType,
  FC,
  ReactNode,
  useState,
  TransitionEventHandler,
} from 'react';
import styled, { css } from 'styled-components';
import {
  AcceptIcon,
  ErrorIcon,
  InfoIcon,
  StarIcon,
  WarningIcon,
  CancelIcon,
} from 'tombac-icons';
import { ThemeColor, tombac } from '../../shared';
import { Box } from '../Styling/Box';
import { Label, Text } from '../Typography';
import { AlertType } from './alertTypes';
import { propStyling, PropsWithPropStyling } from '../../shared/propStyling';

const AlertContainer = styled.div<{
  isOpen?: boolean;
  animationState: string;
}>`
  box-sizing: border-box;
  opacity: 1;
  display: block;
  ${({ isOpen, animationState }) =>
    css`
      opacity: ${() => (isOpen ? '1' : '0')};
      max-height: ${() => (isOpen ? '100%' : '0')};
      transition: all 0.2s;
      transition-timing-function: ease-out;
      display: ${() =>
        !isOpen && animationState === 'exited' ? 'none' : 'block'};
    `}
  ${propStyling};
`;

const DismissButton = styled.div`
  align-self: stretch;
  background: none;
  font-size: inherit;
  height: auto;
  padding: 0;
  width: ${tombac.space(6)};
`;

const AlertToolbar = styled.div`
  white-space: nowrap;
  margin-left: auto;
`;

const AlertLayout = styled.div<{
  alertColor: ThemeColor;
}>`
  all: initial;
  display: flex;
  position: relative;
  padding: ${tombac.space(2, 0)};

  ${({ alertColor }) =>
    css`
      background-color: ${tombac.color(alertColor, 100)};
      border: ${tombac.unit(1)} solid ${tombac.color(alertColor, 500)};
    `};

  ${DismissButton} {
    margin: ${tombac.space(-2, 0)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${({ alertColor }) =>
      css`
        border-left: ${tombac.unit(1)} solid ${tombac.color(alertColor, 500)};
        color: ${tombac.color(alertColor, 500)};
      `};
    &:hover {
      background: ${({ alertColor }) => tombac.color(alertColor, 200)};
    }
  }
`;

const AlertIcon = styled.div<{ $color: ThemeColor }>`
  all: initial;
  display: block;
  padding-left: ${tombac.space(1)};
  margin-right: ${tombac.space(-1)};
  ${({ $color }) =>
    $color === 'neutral'
      ? css`
          color: ${tombac.color($color, 900)};
        `
      : css`
          color: ${tombac.color($color)};
        `}
`;

export interface AlertCoreProps {
  title: string;
  variant: AlertType;
  isOpen?: boolean;
  icon?: ReactNode;
  toolbar?: ReactNode;
  onRequestClose?: () => void;
}

const alertTypeIconMap: Record<AlertType, ElementType> = {
  alert: WarningIcon,
  danger: ErrorIcon,
  default: StarIcon,
  info: InfoIcon,
  success: AcceptIcon,
};

const alertTypeColorMap: Record<AlertType, ThemeColor> = {
  success: 'success',
  alert: 'alert',
  danger: 'danger',
  info: 'accent',
  default: 'neutral',
};

type AlertProps = PropsWithPropStyling<AlertCoreProps>;

export const Alert: FC<AlertProps> = ({
  title,
  variant,
  children,
  toolbar,
  icon,
  onRequestClose,
  isOpen = true,
  ...stylingProps
}) => {
  const [animationState, setAnimationState] = useState('exited');

  const Icon = alertTypeIconMap[variant];
  const color = alertTypeColorMap[variant];

  const handleTransitionEnd: TransitionEventHandler = (event) => {
    const { propertyName } = event;
    setAnimationState((state) => {
      if (state === 'exiting' && propertyName === 'opacity') {
        return 'exited';
      }
      return state;
    });
  };

  const onClose = () => {
    setAnimationState('exiting');
    if (onRequestClose) {
      onRequestClose();
    }
  };

  return (
    <AlertContainer
      {...stylingProps}
      isOpen={isOpen}
      onTransitionEnd={handleTransitionEnd}
      animationState={animationState}
    >
      <AlertLayout alertColor={color}>
        <AlertIcon $color={color}>
          {typeof icon === 'undefined' ? <Icon size="lg" /> : icon}
        </AlertIcon>

        <Box
          $display="flex"
          $flexDirection={toolbar ? 'row' : 'column'}
          $alignItems={toolbar ? 'center' : 'baseline'}
          $pl="2sp"
          $pr="2sp"
          $mt="3u"
          $width="100%"
          $alignSelf="center"
        >
          <div>
            <Label as="div" fontSize={12}>
              {title}
            </Label>
            <Text as="div">{children}</Text>
          </div>
          {toolbar && <AlertToolbar>{toolbar}</AlertToolbar>}
        </Box>
        {onRequestClose && (
          <DismissButton onClick={onClose}>
            <CancelIcon />
          </DismissButton>
        )}
      </AlertLayout>
    </AlertContainer>
  );
};
