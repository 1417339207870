import * as React from 'react';

function DateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="4 0 16 16" height="16" {...props}>
      <path
        d="M15 0a1 1 0 011 1h4v15H4V1h4a1 1 0 112 0h4a1 1 0 011-1zm3 6H6v8h12zM9 7v2H7V7zm4 0v2h-2V7zm4 0v2h-2V7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DateIcon;
