import * as React from 'react';

function ChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="8 0 8 16" height="16" {...props}>
      <path
        d="M13.94 8l-5.47 5.47a.75.75 0 001.06 1.06l6-6a.75.75 0 000-1.06l-6-6a.75.75 0 00-1.06 1.06z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ChevronRightIcon;
